import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home Page" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <img src="/header.png" alt="header" />
      <h2 {...{
        "id": "introduction"
      }}>{`Introduction`}</h2>
      <p>{`Pal.js stands for `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`I am your friend!`}</code>{`. It is a set of tools to help you bootstrap your next NodeJS, Prisma, GraphQL, React project. The library currently contains the following tools and examples:`}</p>
      <p><strong parentName="p"><em parentName="strong">{`Tools`}</em></strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/plugins/select"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`Prisma-select`}</code></a>{` - An extension to tackle down N+1 issues with better query construction using Prisma query engine.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/plugins/delete"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`Prisma-delete`}</code></a>{` - An extension on documenting prisma.schema on dealing with cascade deletes.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`Generator Class`}</code></a>{` - Auto CRUD generations based on your prisma schema for SDL, Graphql-modules, and Nexus`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/prisma-admin"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`Prisma-Table`}</code></a>{` - Front end components that interacts with pal.js auto generated mutation and queries.`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "/cli/create#starter-examples"
        }}><strong parentName="a"><em parentName="strong">{`Examples`}</em></strong></a></p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`full-stack-nextjs`}</code>{` - A full stack admin framework using nexus and next.js.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`full-stack-gatsbyjs`}</code>{` - A full stack admin framework using nexus and gatsby.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`apollo-nexus-schema`}</code>{` - A server side app that uses apollo-sever and nexus with pal.js auto generations.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`apollo-sdl-first`}</code>{` - A server side app that uses sdl-first approach and apollo server with pal.js auto generations.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`graphql-modules`}</code>{` - A server side app that uses apollo server and graphql-modules toolsets with pal.js auto generations.`}</li>
      </ul>
      <h2 {...{
        "id": "requirements"
      }}>{`Requirements`}</h2>
      <p>{`To install `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`@paljs/cli`}</code>{` on your machine, make sure to have the following tools installed:`}</p>
      <ul>
        <li parentName="ul">{`Git - `}<a parentName="li" {...{
            "href": "https://git-scm.com"
          }}>{`https://git-scm.com`}</a></li>
        <li parentName="ul">{`Node.js - `}<a parentName="li" {...{
            "href": "https://nodejs.org"
          }}>{`https://nodejs.org`}</a>{` Please note the `}<strong parentName="li">{`version`}</strong>{` should be `}<strong parentName="li">{`>=12`}</strong></li>
        <li parentName="ul">{`Yarn - `}<a parentName="li" {...{
            "href": "https://classic.yarnpkg.com/en/docs/install"
          }}>{`https://classic.yarnpkg.com/en/docs/install`}</a>{` Node.js package manager, comes with Node.js.`}</li>
        <li parentName="ul">{`Prisma - `}<a parentName="li" {...{
            "href": "https://www.prisma.io/docs/reference/tools-and-interfaces/prisma-cli/installation"
          }}>{`CLI`}</a>{` Modern Database Access for TypeScript & Node.js`}</li>
      </ul>
      <h2 {...{
        "id": "install-paljs-cli"
      }}>{`Install PalJS CLI`}</h2>
      <p>{`To install PalJS CLI globally on your machine, please run the commands below.`}</p>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/cli"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/cli.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/cli"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/cli.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/cli.svg",
            "alt": "License"
          }}></img></a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` global `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` @paljs/cli
//or
`}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`install`}</span>{` -g @paljs/cli
// or use npx to create new project direct
npx @paljs/cli create`}</code></pre></div>
      <h2 {...{
        "id": "create-a-new"
      }}>{`Create a new`}</h2>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span>{` npx @paljs/cli create`}</span>{`

.______      ___       __             __       _______.
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`   _  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`    /   `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`     `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`           `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`     /       `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  /  ^  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`           `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`   `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`----`}<span parentName="code" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`\``}</span>{`
`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`   ___/  /  /_`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`   `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     .--.  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`   `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`
`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     /  _____  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\``}</span></span>{`----.`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  \`--'  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` .----`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`   `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` _`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`    /__/     `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`__`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_______`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`______/  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_______/

`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ Please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your start example · full-stack-nextjs`}</span>{`❯ full-stack-nextjs
  full-stack-gatsbyjs
  apollo-nexus-schema
  apollo-sdl-first
  graphql-modules
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ Please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your start framework · Tailwind CSS + PrismaAdmin UI`}</span>{`  Material UI
  Material UI + PrismaAdmin UI
  Tailwind CSS
❯ Tailwind CSS + PrismaAdmin UI
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ Use multi schema template … `}</span>{`❯ no
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ please enter your project name · great-project`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ please enter your project description · new NodeJs Prisma GraphQL TypeScript project`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ please enter your project author · Ahmed Elywa`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ please enter your project repository · https://github.com/paljs/prisma-tools`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ Do you need to use Git … `}</span>{`❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
  no
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your package manager · `}<span parentName="span" {...{
                "className": "token function"
              }}>{`yarn`}</span></span>{`❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`✔ Skip package installation · no`}</span>{`❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
  no`}</code></pre></div>
      <p>{`Will get 7 questions to help cli create what you want.`}</p>
      <p>{`Look to our examples contains `}<a parentName="p" {...{
          "href": "/cli/create#starter-examples"
        }}>{`here`}</a></p>
      <h2 {...{
        "id": "get-your-project-up"
      }}>{`Get your project up`}</h2>
      <p>{`1 Go inside your project dir`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token builtin class-name"
            }}>{`cd`}</span>{` hello`}</code></pre></div>
      <p>{`Open `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`README.md`}</code>{` file to see next steps.`}</p>
      <p>{`To build your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file look `}<a parentName="p" {...{
          "href": "https://www.prisma.io/docs/reference/tools-and-interfaces/prisma-schema"
        }}>{`here`}</a></p>
      <h3 {...{
        "id": "have-questions"
      }}>{`Have questions?`}</h3>
      <p>{`Didn't find something here? Look through the `}<a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/issues"
        }}>{`issues`}</a>{` or simply drop us a line at `}<a parentName="p" {...{
          "href": "mailto:hello@paljs.com"
        }}>{`hello@paljs.com`}</a>{`.`}</p>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      